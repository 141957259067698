import React, { useState, useEffect } from "react";
import Markdown from "react-remarkable-with-plugins";

import { getCohortMilestonesById } from "api/db";

export default function CourseEvent({ event, cohortNum, courseName, color }) {
  const [milestone, setMilestone] = useState(null);

  useEffect(() => {
    setMilestone(null);

    if (cohortNum != null && event && courseName) {
      getCohortMilestonesById(courseName, cohortNum, event).then(snapshot => {
        setMilestone(snapshot.val());
      });
    }
  }, [cohortNum, event, courseName]);

  return (
    <div className="bg-white">
      <section className="mx-auto max-w-screen-xl sm:px-6 lg:px-8 mt-8">
        {milestone ? (
          <div className="p-4 sm:px-2 lg:px-0">
            <div
              className={`py-1 bg-${color}-700 hover:bg-${color}-600 items-center text-${color}-100 leading-none rounded-full flex inline-flex mb-2 cursor-pointer"
              role="alert`}
            >
              <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                {courseName == "web-development" ? (
                  <>WEB DEVELOPMENT COHORT</>
                ) : (
                  <>FIGMA</>
                )}
              </span>
            </div>
            <h1 className="text-gray-700 text-sm uppercase mt-2">
              {new Date(milestone.datetime).toLocaleString()}
            </h1>
            <h1 className="text-3xl tracking-tight text-gray-900 font-bold">
              {milestone.name}
            </h1>
            <h2 className="text-gray-700 text-base">{milestone.description}</h2>

            {Date.now() > milestone.datetime - 600000 ? (
              <div>
                {Date.now() > milestone.datetime + 7200000 ? ( // recording two hours after event start
                  <div className="relative h-0 pb-fluid-video mt-4">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full"
                      src={`${milestone.recording}?portrait=0&byline=0&autoplay=1&loop=0`}
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                    />
                  </div>
                ) : (
                  <>
                    <p className="p-4 text-2xl  text-gray-800">
                      Click{" "}
                      <a
                        className="text-blue-600 font-bold"
                        href="https://zoom.enlight.nyc"
                      >
                        here
                      </a>{" "}
                      to go to the event.
                    </p>
                  </>
                )}{" "}
                <h1 className="text-base tracking-tight text-gray-800 font-bold mt-4">
                  Resources
                </h1>
                <div className="text-gray-700 text-base mt-2">
                  <Markdown>{milestone.resources}</Markdown>
                </div>
              </div>
            ) : (
              <h1 className="mt-2">This event has not started yet.</h1>
            )}
          </div>
        ) : (
          <></>
        )}
      </section>
    </div>
  );
}
