import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { navigate } from "gatsby";
import CourseEvent from "components/Courses/Event";

const Event = ({ location }) => {
  if (typeof window !== `undefined`) {
    if (
      location &&
      location.state &&
      location.state.userData &&
      location.state.eventName &&
      location.state.cohortNum != null
    ) {
      var userData = location.state.userData;
      var event = location.state.eventName;
      var cohortNum = location.state.cohortNum;
      let acceptedTags = ["team", "mentor"];

      if (
        !(
          (userData &&
            userData.courses &&
            userData.courses.find((s) => s.includes("f"))) ||
          (userData &&
            userData.instructor &&
            userData.instructor.find((s) => s.includes("f"))) ||
          (userData &&
            userData.tags &&
            acceptedTags.some((tag) => userData.tags.includes(tag)))
        )
      ) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }

  return (
    <>
      <Helmet title="Event — Prototyping with Figma | Enlight" />
      <CourseEvent event={event} cohortNum={cohortNum} courseName="figma" color="purple" />
    </>
  );
};

export default Event;
